//import logo from './logo.svg';
import certificate from './certificate.jpg';
//import './App.css';
import { useState, useEffect  } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import WebFont from 'webfontloader';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      
    },
    
  },
  
}));

function App() {
  
  const classes = useStyles();
   const [coins, setCoins] = useState(0);
   const [hunterName, setHunterName] = useState('');
    const [formVisible, setFormVisible] = useState(true);
     const [top, setTop] = useState(345);
      const [topLower, setTopLower] = useState(500);
   
   useEffect(() => {
  WebFont.load({
    google: {
      families: ['Sriracha']
    }
  });
 }, []);
  
  /*
  style={{
                          
                          position: 'absolute', 
                          color: 'BLUE', 
                          top: 20, 
                          left: '50%', 
                          transform: 'translateX(100%)'
                        }} 
  */
  
  function printThis(){
    setFormVisible(false);
    setTop(top- 75);
    setTopLower(topLower - 75);
  }
  
  function getForm(){
    if(formVisible === false){
      return(<div/>);
    }
    return(
      <form className={classes.root} noValidate autoComplete="off">
      
      <TextField id="outlined-basic" label="Name of hunter" variant="outlined" onChange={(event) => setHunterName(event.target.value)}  />
      <TextField id="outlined-basic" label="Metals collected" variant="outlined" onChange={(event) => setCoins(event.target.value)} />
       <Button variant="outlined"  size="large" color="primary"  onClick={() => printThis()}>
        Print certificate!
      </Button>
    </form>
  );
  }
  
  return (
    <div>
      <header className="App-header">
      {getForm()}
      
     <div  >
      <img src={certificate}  width={3508/3} height={2480/3} />
    
       <div  style={{
                    fontFamily: 'Sriracha',
                    position: 'absolute', 
                          color: 'BLUE', 
                          top: top, 
                          left: 220, 
                          fontSize: '50px'
         
       }} >
         <b>{hunterName}</b>
       </div>
    
    
     <div  style={{
                    fontFamily: 'Sriracha',
                    position: 'absolute', 
                          color: 'BLUE', 
                          top: topLower, 
                          left: 700, 
                          fontSize: '50px'
         
       }} >
         <b>{coins}</b>
       </div>
    
    </div>
      </header>
    </div>
  );
}

export default App;
